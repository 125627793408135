import { ReactElement, ReactNode } from "react";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useHasFeature from "@hooks/use-has-feature";
import { useGetTier } from "@hooks/use-tier";
import { FeatureNames } from "@lib/constants/featureNames";

export const ConditionalTeamsRenderer: React.FC<{
  defaultContent?: ReactElement;
  children: ReactElement;
}> = ({ defaultContent = null, children }) => {
  const {
    oid,
    organizationAccounts: { hasMoreThanOneMember },
  } = useAuth();
  const [hasMembersFeature, loading] = useHasFeature(oid, FeatureNames.members);
  const tier = useGetTier();

  if (loading) return null;

  const hasTierAccess =
    ["teams", "business"].includes(tier ?? "") ||
    (tier === "pro" && hasMoreThanOneMember);

  if (hasMembersFeature && hasTierAccess) return children;

  return defaultContent;
};

export const ConditionalBusinessRenderer: React.FC<{
  defaultContent?: ReactElement;
  children: ReactNode;
}> = ({ defaultContent = null, children }) => {
  const { oid } = useAuth();
  const [hasBusinessFeature, loading] = useHasFeature(
    oid,
    FeatureNames.accountsPayable
  );

  if (loading) return null;

  if (hasBusinessFeature) return <>{children}</>;

  return defaultContent;
};

export const ConditionalAccessTypeRenderer: React.FC<{
  limitedAccessContent?: ReactElement;
  children: ReactElement;
}> = ({ limitedAccessContent = null, children }) => {
  const { accessType, loading: loadingAccessType } = useAccessType();

  if (loadingAccessType) return null;

  if (accessType === "full") return children;

  return limitedAccessContent;
};

export const ConditionalAccountsPayableRenderer: React.FC<{
  defaultContent?: ReactElement;
  children: ReactElement;
}> = ({ defaultContent = null, children }) => {
  const { oid } = useAuth();
  const [hasAccountsPayableFeature, loading] = useHasFeature(
    oid,
    FeatureNames.accountsPayable
  );

  if (loading) return null;

  if (hasAccountsPayableFeature) return children;

  return defaultContent;
};
